<template>
  <v-app id="app-wrapper">
      <v-app-bar app dark>
      <div class="d-flex align-center mr-2">
        <img :src="require('@/assets/renaps.svg')" alt="logo" width="150">
      </div>
       <v-spacer />
       <div class="d-flex align-center mr-2">
           Email Signature Generator
       </div>
    </v-app-bar>

      <v-main id="main-wrapper">
      <EmailSignature/>
    </v-main>
  </v-app>
</template>

<script>
import EmailSignature from './components/EmailSignature';

export default {
  name: 'App',
  components: {
    EmailSignature,
  }
};
</script>
<style scoped>
#app-wrapper {
  margin:15px;
}
#main-wrapper {
  margin-top:40px;
}
</style>
