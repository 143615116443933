<template>
<div>
    <div class="email-signature-preview">
        <div class="name">{{firstName}} {{lastName}}</div>
        <div class="role">{{roleEnglish}} | {{roleFrench}}</div>
        <div class="adr1">{{mainAddressStreet}}, {{mainAddressCity}}, ({{mainAddressState}}) {{mainAddressCountry}} {{mainAddressPostalCode}}</div>
        <div class="adr2">{{secondaryAddress}}</div>
        <div class="phone">{{phoneText}}</div>
        <div class="cell">Cell: {{cellText}} | Fax: {{faxText}}</div>
        <div class="email"><a :href="emailLink"><span>{{email}}</span></a></div>
        <div class="websiteToLink"><a href="https://www.renaps.com" target="_blank"><span>{{website}}</span></a></div>
        <div class="logo"><a href="https://www.renaps.com"><img src="https://www.renaps.com/emailsignature/renaps-signature-large.jpg" width="280" style="margin-left:0px;"></a></div>
        <div class="qrcode" style="margin-left:-15px;">
            <QrCodeVCard
              :firstName="firstName"
              :lastName="lastName"
              :workPhone="phone"
              :cellPhone="cell"
              :workEmail="email"
              :orgName="company"
              :orgTitle="roleEnglish"
              :orgStreet="mainAddressStreet"
              :orgCity="mainAddressCity"
              :orgRegion="mainAddressState"
              :orgCountry="mainAddressCountry"
              :orgPost="mainAddressPostalCode"
              v-on:update="setCodeURL"
            />
        </div>
        <div class="privacy-note"><p>This message (including any attachments) is CONFIDENTIAL and may be PRIVILEGED. If you are not an intended recipient you are hereby notified that any distribution, copying or use by you of this information is strictly prohibited. If you have received this message in error please immediately notify the sender and delete all copies of this information from your system.</p><p>L'information contenue dans le présent courriel (y compris les pièces jointes, le cas échéant) est CONFIDENTIELLE et peut être PRIVILÉGIÉE. Si vous n’êtes pas le destinataire prévu, vous êtes par la présente avisé(e) que toute diffusion, copie ou utilisation de ladite information est strictement interdite. Si vous avez reçu cette communication par erreur, veuillez nous en aviser immédiatement en répondant à l'expéditeur et effacer de votre ordinateur toute trace de cette information.</p></div>
    </div>
    <div class="email-signature-buttons">
        <v-btn color="primary" @click="downloadEmailSignature">Download</v-btn>
    </div>
</div>
</template>

<script>
import QrCodeVCard from './QrCodeVCard.vue';
import { saveAs } from 'file-saver';

export default {
    name: 'EmailSignaturePreview',
    components: {
        QrCodeVCard
    },
    props: {
        firstName: String,
        lastName: String,
        roleFrench: String,
        roleEnglish: String,
        phone: String,
        phoneText: String,
        email: String,
        cellText: String,
        cell: String,
        faxText: String,
        fax: String,
        website: String,
        company: String,
        mainAddressStreet: String,
        mainAddressCity: String,
        mainAddressState: String,
        mainAddressCountry: String,
        mainAddressPostalCode: String,
        secondaryAddress: String
    },
    data: function() {
        return {
            emailLink: "mailto:" + this.email,
            websiteLink: 'http://' + this.website,
            logoLink: 'http://' + this.website,
            mainAddress: this.mainAddressStreet+', '+this.mainAddressCity+', ('+this.mainAddressState+') '+this.mainAddressCountry+' '+this.mainAddressPostalCode,
            qrCode: '',
            codeURL: ''
        }
    },
    methods: {
      getEmailSignatureCode: function(){
          let baseCSS = "color:#666;line-height:20px;font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;";
          return `<div style="${baseCSS}font-weight: bold;font-size: 16px;">${this.firstName} ${this.lastName}</div>
      <div style="${baseCSS}font-weight: normal;font-size: 15px;color: #999;">${this.roleEnglish} | ${this.roleFrench}</div>
      <div style="${baseCSS}margin-top: 22px;font-size: 13px;line-height: 24px;color: #222;">${this.mainAddress}</div>
      <div style="${baseCSS}font-size: 13px;line-height: 24px;color: #222;">${this.secondaryAddress}</div>
      <div style="${baseCSS}font-size: 13px;line-height: 24px;color: #222;">${this.phoneText}</div>
      <div style="${baseCSS}font-size: 13px;line-height: 24px;color: #222;">Cell: ${this.cellText} | Fax: ${this.faxText}</div>
      <div style="${baseCSS}font-size: 13px;line-height: 24px;color: #222;"><a style="${baseCSS}font-size: 13px;line-height: 24px;color: #222!important;text-decoration:underline!important;" href="emailLink"><span style="color: #222;text-decoration:underline!important;">${this.email}</span></a></div>
      <div style="${baseCSS}font-size: 13px;line-height: 24px;color: #222;"><a style="${baseCSS}font-size: 13px;line-height: 24px;color: #222!important;text-decoration:underline!important;" href="https://www.renaps.com" target="_blank"><span style="color: #222;text-decoration:underline!important;">${this.website}</span></a></div>
      <div style="margin-top: 20px;"><a href="https://www.renaps.com" style="${baseCSS}font-size: 13px;line-height: 24px;color: #222;text-decoration:none;"><img src="https://www.renaps.com/emailsignature/renaps-signature-large.jpg" width="280" style="margin-left:0px;"></a></div>
      <div style="opacity: 0.7; margin-left:-14px;"><img src="${this.codeURL}" width="170"></div>
      <div style="${baseCSS}color:#999;font-size:10px;line-height:24px;"><p>This message (including any attachments) is CONFIDENTIAL and may be PRIVILEGED. If you are not an intended recipient you are hereby notified that any distribution, copying or use by you of this information is strictly prohibited. If you have received this message in error please immediately notify the sender and delete all copies of this information from your system.</p><p>L'information contenue dans le présent courriel (y compris les pièces jointes, le cas échéant) est CONFIDENTIELLE et peut être PRIVILÉGIÉE. Si vous n’êtes pas le destinataire prévu, vous êtes par la présente avisé(e) que toute diffusion, copie ou utilisation de ladite information est strictement interdite. Si vous avez reçu cette communication par erreur, veuillez nous en aviser immédiatement en répondant à l'expéditeur et effacer de votre ordinateur toute trace de cette information.</p></div>`;
  },
      downloadEmailSignature: function() {
          let test = true;
          const todaysDate = new Date().toDateString();
          try {
              test = !!new Blob;
              if (test) {
                  let blob = new Blob([this.getEmailSignatureCode()], {type: "text/html;charset=utf-8"});
                  return saveAs(blob, "EmailSignature-"+this.firstName+this.lastName+"-"+todaysDate+".html");
              }
          }
          catch (e) {
              test=false;
              console.error('Blob not supported to download file! Use a more recent browser!');
          }
          return false;
      },
      copyEmailSignature: function(){
          navigator.clipboard.writeText(this.getEmailSignatureCode());
      },
      setCodeURL: function(url) {
        console.info('set code url = '+url);
        this.codeURL = url;
      }
    }
}
</script>

<style scoped>
.email-signature-preview {
    border: 1px solid #666;
    color: #666;
    padding: 30px;
    line-height: 20px;
    font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
}

.name {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
}

.role {
    font-weight: normal;
    font-size: 15px;
    color: #999;
    line-height: 20px;
    font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
}

.adr1 {
    margin-top: 22px;
}

.adr1,
.adr2,
.phone,
.cell,
.email,
.websiteToLink,
.privacy-note {
    font-size: 13px;
    line-height: 24px;
    color: #222;
    font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
}
.privacy-note{
    color:#999;
    font-size:10px;
    line-height:24px;
}
.email a,
.websiteToLink a {
    text-decoration: underline;
    color: #222;
}

.logo {
    margin-top: 20px;
}

.qrcode {
    opacity: 0.7;
    margin-left: -20px;
}
.email-signature-buttons{
    margin-top:20px;
}
.email-signature-buttons button{
    margin-right:20px;
}

</style>
