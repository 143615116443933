<template>
<div>
    <v-form ref="form" lazy-validation>
        <v-layout row wrap>
            <v-flex xs12 md6 px-5>
                <v-layout row wrap>
                    <v-flex xs12 md6 px-5>
                        <v-text-field v-model="firstName" label="First Name" ref="firstName" :rules="requiredNotEmpty"></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6 px-5>
                        <v-text-field v-model="lastName" label="Last Name" ref="lastName" :rules="requiredNotEmpty"></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6 px-5>
                        <v-text-field v-model="roleFrench" label="Job title (French)" ref="roleFrench" :rules="requiredNotEmpty"></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6 px-5>
                        <v-text-field v-model="roleEnglish" label="Job title (English)" ref="roleEnglish" :rules="requiredNotEmpty"></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6 px-5>
                        <v-text-field v-model="phoneText" label="Phone (Readable Text Version)" ref="phoneText" :rules="requiredNotEmpty"></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6 px-5>
                        <v-text-field v-model="phone" label="Phone VCard Dial #" ref="phone" :rules="requiredPhoneNumber"></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6 px-5>
                        <v-text-field v-model="cellText" label="Cellphone (Readable Text Version)" ref="cellText"></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6 px-5>
                        <v-text-field v-model="cell" label="Cellphone VCard Dial #" ref="cell" :rules="optionalPhoneNumber"></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6 px-5>
                        <v-text-field v-model="faxText" label="Fax (Readable Text Version)" ref="faxText" :rules="requiredNotEmpty"></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6 px-5>
                        <v-text-field v-model="fax" label="Fax VCard Dial #" ref="fax" :rules="requiredPhoneNumber"></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6 px-5>
                        <v-text-field v-model="email" label="Email" ref="email" :rules="requiredEmail"></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6 px-5>
                        <v-text-field v-model="website" label="Website" ref="website" :rules="requiredNotEmpty"></v-text-field>
                    </v-flex>
                    <v-flex xs12 md4 px-5>
                        <v-text-field v-model="mainAddressStreet" label="Main Address" ref="mainAddressStreet"></v-text-field>
                    </v-flex>
                    <v-flex xs12 md2 px-5>
                        <v-text-field v-model="mainAddressCity" label="City" ref="mainAddressCity"></v-text-field>
                    </v-flex>
                    <v-flex xs12 md2 px-5>
                        <v-text-field v-model="mainAddressState" label="State/Prov" ref="mainAddressState"></v-text-field>
                    </v-flex>
                    <v-flex xs12 md2 px-5>
                        <v-text-field v-model="mainAddressCountry" label="Country" ref="mainAddressCountry"></v-text-field>
                    </v-flex>
                    <v-flex xs12 md2 px-5>
                        <v-text-field v-model="mainAddressPostalCode" label="Postal Code" ref="mainAddressPostalCode"></v-text-field>
                    </v-flex>
                    <v-flex xs12 md12 px-5>
                        <v-text-field v-model="secondaryAddress" label="Secondary Address" ref="secondaryAddress"></v-text-field>
                    </v-flex>
                    <v-flex xs12 md12 px-5>
                        <div class="instructions">
                            <h2>Instructions</h2>
                            <p>For Outlook365 Online, download file and open it in your favorite browser, then copy everything (CTRL+A followed by CTRL+C) and paste it in the signature box (CTRL+V) in your outlook settings. It will retain formatting.</p>
                            <p>For Outlook365 Desktop version, first download the file using the download button. Then follow instructions <a href="https://lazyadmin.nl/office-365/outlook-html-signature/">here</a> to retain formatting, pasting the file content in the notepad step (step #5).</p>
                        </div>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex xs12 md6 px-5>
                <EmailSignaturePreview v-bind:firstName="firstName" v-bind:lastName="lastName" v-bind:roleFrench="roleFrench" v-bind:roleEnglish="roleEnglish" v-bind:mainAddressStreet="mainAddressStreet" v-bind:mainAddressCity="mainAddressCity"
                    v-bind:mainAddressState="mainAddressState" v-bind:mainAddressCountry="mainAddressCountry" v-bind:mainAddressPostalCode="mainAddressPostalCode" v-bind:secondaryAddress="secondaryAddress" v-bind:phoneText="phoneText" v-bind:phone="phone"
                    v-bind:company="company" v-bind:email="email" v-bind:cellText="cellText" v-bind:cell="cell" v-bind:faxText="faxText" v-bind:fax="fax" v-bind:website="website" />
            </v-flex>
        </v-layout>
    </v-form>
</div>
</template>
<script>
import EmailSignaturePreview from './EmailSignaturePreview.vue'

export default {
    name: 'EmailSignature',
    components: {
        EmailSignaturePreview
    },
    data() {
        return {
            firstName: "",
            lastName: "",
            roleFrench: "",
            roleEnglish: "",
            mainAddressStreet: "101 Marcel-Laurin, suite 300",
            mainAddressCity: "Montréal",
            mainAddressState: "Québec",
            mainAddressCountry: "Canada",
            mainAddressPostalCode: "H4N 2M3",
            secondaryAddress: "2875 NE 191 Street, Suite 500, Aventura, Florida 33180, USA",
            phoneText: "1-888-98-RENAPS (1-888-987-3627 / 514-360-0394) ext. 101",
            phone: "18889873627#101",
            cellText: "514-892-9920",
            cell: "15148929920",
            faxText: "514-221-3647",
            fax: "15142213647",
            email: "phamou@renaps.com",
            website: "www.renaps.com",
            company: "RENAPS",
            requiredNotEmpty:[(v) => !!v || 'This field is required!'],
            requiredPhoneNumber:[
                (v) => !!v || 'This field is required!',
                (v) => (/[0-9#;,]{10,}$/).test(v) || 'Only numbers and #,; chars allowed!'
            ],
            optionalPhoneNumber:[
                (v) => (/[0-9#;,]{10,}$/.test(v) || !v) || 'Only numbers and #,; chars allowed!'
            ],
            requiredEmail:[
                v => !!v || 'E-mail is required',
                v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
            ]
        };
    },
}
</script>
<style scoped>
.instructions {
    margin-top: 30px;
}
</style>
